<template>
  <div>
    <div class="top-bar flex flex-col justify-between">
      <div class="account-title">Profile</div>
      <div class="nav-bar flex justify-between sm:justify-start">
        <div
          :class="['bar-list', { is_active: currentTab === 'Account' }]"
          v-on:click="handleChangeTab"
        >
          Account
        </div>
        <!-- <div
          :class="['bar-list', { is_active: currentTab === 'Security' }]"
          v-on:click="handleChangeTab"
        >
          Security
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <transition mode="out-in" :name="transition_name">
        <component v-bind:is="currentTabComponent"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import ProfileAccount from '@/components/User/ProfileAccount.vue'
// import ProfileSecurity from '@/components/User/ProfileSecurity.vue'
import { mapState } from 'vuex'

export default {
  methods: {
    handleChangeTab (e) {
      const fromIndex = this.tabs.indexOf(this.currentTab)
      const component = e.target.innerText
      const toIndex = this.tabs.indexOf(component)
      if (toIndex < fromIndex) {
        this.transition_name = 'swipe-right'
      } else {
        this.transition_name = 'swipe-left'
      }
      this.currentTab = component
    }
  },
  components: {
    Account: ProfileAccount
  },
  data () {
    return {
      tabs: ['Account', 'Security'],
      currentTab: 'Account',
      transition_name: 'swipe-left'
    }
  },
  computed: {
    currentTabComponent () {
      return this.currentTab
    },
    ...mapState('login', ['status', 'attributes'])
  }
}
</script>

<style scoped>
.marginRL60 {
  margin: 0 60px;
}
.bottom {
  padding-top: 25px;
  padding-left: 24px;
  display: flex;
}
.is_active {
  border-bottom: 3px solid #bc9a41;
}

.swipe-left-enter {
  opacity: 0;
  transform: translateX(50%);
}
.swipe-left-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}
.swipe-left-enter-active,
.swipe-left-leave-active {
  transition: all 0.3s ease;
}
.swipe-right-enter {
  opacity: 0;
  transform: translateX(-50%);
}
.swipe-right-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
.swipe-right-enter-active,
.swipe-right-leave-active {
  transition: all 0.3s ease;
}
@media only screen and (max-width: 600px) {
  .marginRL60 {
    margin: 0 60px;
  }
  .bottom {
    padding: 5vw;
    display: flex;
  }
  .is_active {
    border-bottom: 3px solid #bc9a41;
    color: #163257;
  }
}
</style>
