import { render, staticRenderFns } from "./ProfileAccount.vue?vue&type=template&id=b25a8390&scoped=true&"
import script from "./ProfileAccount.vue?vue&type=script&lang=js&"
export * from "./ProfileAccount.vue?vue&type=script&lang=js&"
import style0 from "./ProfileAccount.vue?vue&type=style&index=0&id=b25a8390&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b25a8390",
  null
  
)

export default component.exports